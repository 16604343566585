import { useTranslation } from "next-i18next";
import Image from "next/image";
import styled from "styled-components";
const Services = () => {
  const { t } = useTranslation();

  return (
    <StyledServicesWrapper id="Services" className="w-full py-14 bg-primary">
      <div className="text-center">
        <div className="pb-10 flex justify-center text-3xl md:text-4xl ">
          <h2 className="text-indigo-600 pr-2">{t("our")}</h2>
          <h2 className="font-extralight">{t("services")}</h2>
        </div>
      </div>

      <div className="w-4/5 mx-auto py-2 lg:flex lg:justify-evenly text-center space-y-10 lg:space-y-0">
        <div className="rounded-2xl shadow-xl w-full lg:w-3/12">
          <div className="flex justify-center p-10 bg-indigo-600 rounded-t-2xl">
            <Image
              src={"/images/web-developer.svg"}
              alt="Web developer"
              width={300}
              height={200}
            ></Image>
          </div>
          <div className="p-4 text-left">
            <p className="text-indigo-600 text-xl">
              {t("website-development")}
            </p>
            <ul className="list-disc pl-4 py-4 leading-8">
              <li>{t("service-web-application")}</li>
              <li>{t("service-easy-updates")}</li>
              <li>{t("service-latest-technologies")}</li>
            </ul>
          </div>
        </div>
        <div className="rounded-2xl shadow-xl w-full lg:w-3/12">
          <div className="flex justify-center p-10 bg-indigo-600 rounded-t-2xl">
            <Image
              src={"/images/mobile-development.svg"}
              alt="Web developer"
              width={300}
              height={200}
            ></Image>
          </div>
          <div className="p-4 text-left">
            <p className="text-indigo-600 text-xl">{t("mobile-development")}</p>
            <ul className="list-disc pl-4 py-4 leading-8">
              <li>{t("service-ios-android")}</li>
              <li>{t("service-easy-updates")}</li>
              <li>{t("service-latest-technologies")}</li>
            </ul>
          </div>
        </div>
        <div className="rounded-2xl shadow-xl w-full lg:w-3/12">
          <div className="flex justify-center p-10 bg-indigo-600 rounded-t-2xl">
            <Image
              src={"/images/design.svg"}
              alt="Web developer"
              width={300}
              height={200}
            ></Image>
          </div>
          <div className="p-4 text-left">
            <p className="text-indigo-600 text-xl">{t("ui-ux-design")}</p>
            <ul className="list-disc pl-4 py-4 leading-8">
              <li>{t("pricing-wireframes-prototypes")}</li>
              <li>{t("service-user-focused-designs")}</li>
              <li>{t("service-competitive-audit")}</li>
            </ul>
          </div>
        </div>
      </div>
    </StyledServicesWrapper>
  );
};

export default Services;

const StyledServicesWrapper = styled.div`
  &:after {
    content: "";
    display: block;
    width: 50vw;
    margin: 50px auto 0px auto;
    height: 2px;
    background-color: lightgray;
  }
`;
